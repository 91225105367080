'use strict';

/**
 * @protected
 * @class
 * @constructor
 * @param {String|Number} logLevel - Log level
 */
var Logger = module.exports = function(logLevel) {
  if (typeof logLevel === 'string') {
    logLevel = LogLevels[logLevel];
  }
  if (!logLevel) {
    logLevel = LogLevels.INFO;
  }
  this._logLevel = logLevel;
};

/**
 * @memberof Logger
 */
var LogLevels = Logger.LogLevels = {
  "DEBUG" : 1,
  "INFO" : 2,
  "WARN" : 3,
  "ERROR" : 4,
  "FATAL" : 5
};

/**
 * Output log
 *
 * @param {String} level - Logging target level
 * @param {String} message - Message to log
 */
Logger.prototype.log = function(level, message) {
  if (this._logLevel <= level) {
    if (level < LogLevels.ERROR) {
      console.log(message);
    } else {
      console.error(message);
    }
  }
};

for (var level in LogLevels) {
  Logger.prototype[level.toLowerCase()] = createLoggerFunction(LogLevels[level]);
}

function createLoggerFunction(level) {
  return function(message) { this.log(level, message); };
}
