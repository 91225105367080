/*global window, document */
'use strict';

var _index = 0;

module.exports = {

  supported: typeof window !== 'undefined' && typeof document !== 'undefined',

  createRequest: function(jsonpParam, timeout) {
    jsonpParam = jsonpParam || 'callback';
    timeout = timeout || 10000;

    return function(params, callback) {
      if (params.method.toUpperCase() !== 'GET') {
        return callback(new Error('JSONP only supports GET request.'));
      }
      var cbFuncName = '_jsforce_jsonpCallback_' + (++_index);
      var callbacks = window;
      var url = params.url;
      url += url.indexOf('?')>0 ? '&' : '?';
      url += jsonpParam + '=' + cbFuncName;

      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      document.documentElement.appendChild(script);

      var pid = setTimeout(function() {
        cleanup();
        callback(new Error("JSONP call time out."));
      }, timeout);

      callbacks[cbFuncName] = function(res) {
        cleanup();
        callback(null, {
          statusCode: 200,
          headers: { "content-type": "application/json" },
          body: JSON.stringify(res)
        });
      };

      var cleanup = function() {
        clearTimeout(pid);
        document.documentElement.removeChild(script);
        delete callbacks[cbFuncName];
      };
    };

  }

};