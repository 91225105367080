// Generated by CoffeeScript 1.12.7
var StringDecoder, parse;

StringDecoder = require('string_decoder').StringDecoder;

parse = require('./index');

module.exports = function(data, options) {
  var decoder, err, parser, records;
  if (options == null) {
    options = {};
  }
  records = options.objname ? {} : [];
  if (data instanceof Buffer) {
    decoder = new StringDecoder();
    data = decoder.write(data);
  }
  parser = new parse.Parser(options);
  parser.push = function(record) {
    if (options.objname) {
      return records[record[0]] = record[1];
    } else {
      return records.push(record);
    }
  };
  err = parser.__write(data, false);
  if (err) {
    throw err;
  }
  if (data instanceof Buffer) {
    err = parser.__write(data.end(), true);
    if (err) {
      throw err;
    }
  }
  parser._flush((function() {}));
  return records;
};
