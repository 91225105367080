/*global Sfdc */
'use strict';

var Duplex = require('readable-stream').Duplex,
    _ = require('lodash/core');

function parseHeaders(hs) {
  var headers = {};
  hs.split(/\n/).forEach(function(line) {
    var pair = line.split(/\s*:\s*/);
    var name = pair[0].toLowerCase();
    var value = pair[1];
    headers[name] = value;
  });
  return headers;
}

module.exports = {

  supported: typeof Sfdc === 'object' && typeof Sfdc.canvas !== 'undefined',

  createRequest: function(signedRequest) {
    return function(params, callback) {
      var response;
      var str = new Duplex();
      str._read = function(size) {
        if (response) {
          str.push(response.body);
        }
      };
      var bufs = [];
      var sent = false;
      str._write = function(chunk, encoding, callback) {
        bufs.push(chunk.toString(encoding));
        callback();
      };
      str.on('finish', function() {
        if (!sent) {
          send(bufs.join(''));
          sent = true;
        }
      });
      if (params.body || params.body === "" || !/^(put|post|patch)$/i.test(params.method)) {
        send(params.body);
        sent = true;
      }

      function send(body) {
        var settings = {
          client: signedRequest.client,
          method: params.method,
          data: body
        };
        if (params.headers) {
          settings.headers = {};
          for (var name in params.headers) {
            if (name.toLowerCase() === 'content-type') {
              settings.contentType = params.headers[name];
            } else {
              settings.headers[name] = params.headers[name];
            }
          }
        }
        settings.success = function(data) {
          var headers = parseHeaders(data.responseHeaders);
          var body = data.payload;
          if (!_.isString(body)) {
            body = JSON.stringify(body);
          }
          response = {
            statusCode : data.status,
            headers: headers,
            body: body
          };
          if (callback) {
            callback(null, response, response.body);
          }
          str.end();
        };
        settings.failure = function(err) {
          if (callback) {
            callback(err);
          }
        };
        Sfdc.canvas.client.ajax(params.url, settings);
      }
      return str;
    };
  }
};
